/* website: 2940-boyernapanee2
 * created at 2019-12-122 11:00 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/gmc-preferred.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
  .widget-sr{
    &.dealer__peter-boyer-chevrolet-buick-gmc{
      .payment-calculator-wrapper{
        z-index: auto;
      }
    }
  }
}
